import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(() => {
  return createStyles({
    mainContainer: {},
    infoLableInput: {
      backgroundColor: 'transparent',

      '& .MuiOutlinedInput-root': {
        backgroundColor: '#fff',
        height: '3.6em !important',
        borderRadius: '20px !important',
        fontSize: '14px',
        color: 'black !important',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        border: 'none #important',
        width: '202px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        // boxShadow: '2px 2px 6px grey',
        border: 'none #important',
      },

      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          boxShadow: '2px 2px 6px grey',
          border: 'none #important',
        },
      },

      '& .MuiFormLabel-root': {
        fontSize: '14px',
        color: 'black',
        // fontFamily: 'InterRegular',
        marginTop: '-1px',
      },
    },
  })
})

const DateFilter = (props: any) => {
  const classes = useStyles()
  const { dateStart, dateEnd, setDateStart, setDateEnd } = props

  const handleDateTodayChange = (value: any) => {
    setDateStart(value?.$d)
  }
  const handleDateEndChange = (value: any) => {
    setDateEnd(value?.$d)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <Box>
          <DatePicker
            className={`${classes.infoLableInput}`}
            label='From'
            onChange={handleDateTodayChange}
            value={dateStart}
          />
        </Box>

        <Box>
          {' '}
          <DatePicker
            className={classes.infoLableInput}
            label='To'
            // defaultValue={Today}
            onChange={handleDateEndChange}
            value={dateEnd}
          />
        </Box>
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default DateFilter
