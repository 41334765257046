import React, { useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { ClickAwayListener, Collapse } from '@mui/material'
import {
  ChildList,
  DrawerHeaderStack,
  StyledDrawer,
  StyledListItemButton,
  StyledListItemText,
} from './FinancialStyles'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as AdministrationLogo } from '../../assets/images/home.svg'
import { ReactComponent as FileUploadLogo } from '../../assets/images/file-upload-icon.svg'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { SmallArrow } from '../Common/CommonStyles'
import { SideDrawerMenu as menuObject } from '../../utils/StaticData'
import MenuIcon from '@mui/icons-material/Menu'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const SideDrawer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const listArray = Object.keys(menuObject)
  const [openedIndex, setOpenedIndex] = useState<null | number>(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const location = useLocation()
  const pathName = location?.pathname?.split('/')
  const [userRole, setUserRole] = useState('');

  // Function to get user role from local storage
  const getUserRoleFromLocalStorage = () => {
    const role = localStorage.getItem('role');
    if (role) {
      setUserRole(role);
    }
  };

  // Effect to run once to get user role from local storage
  useEffect(() => {
    getUserRoleFromLocalStorage();
  }, []);

  const handleOpenMenu = (index: number, value: string) => {
    setOpenedIndex(index === openedIndex ? null : index)
    navigate(value)
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const handleClickMenu = (value: string) => {
    if (value === 'benefits') {
      window.open(
        'https://sites.google.com/tudip.com/tudipinternal/tudip-benefits?authuser=0',
        '_blank',
        'noreferrer',
      )
    } else if (value === 'userManual') {
      window.open(
        'https://docs.google.com/document/d/19_YQFCL3Pm6ZeJ0R4tdJZpRlaG1hWtzA3FwZwc64pAA/edit?usp=sharing',
        '_blank',
        'noreferrer',
      )
    } else {
      navigate(value)
    }
    handleOpenDrawer()
  }

  const getListItemIcon = (value: string) => {
    switch (value) {
      case 'dashboard':
        return <AdministrationLogo />
      case 'track_email':
        return <EmailOutlinedIcon />
      case 'email_dashboard':
        return <DashboardCustomizeOutlinedIcon />
      case 'contacts':
        return <ContactsOutlinedIcon/>
      case 'analytics':
        return <BarChartOutlinedIcon />
      case 'dealClosed':
        return <EmojiEventsOutlinedIcon />
      case 'campaign':
        return <CampaignOutlinedIcon />
      case 'addUser':
        return <PersonAddAltOutlinedIcon />
      case 'userManual':
        return <DescriptionOutlinedIcon /> // Add the icon for user manual
      default:
        return <FileUploadLogo />
    }
  }

  return (
<ClickAwayListener
onClickAway={(event: any) => {
  setOpenDrawer(false);
}}
>
<StyledDrawer variant='permanent' anchor='left' open={openDrawer}>
  <DrawerHeaderStack>
    {!openDrawer ? (
      <MenuIcon
        sx={{ cursor: 'pointer', color: 'white', fontSize: '28px' }}
        onClick={handleOpenDrawer}
      />
    ) : (
      <MenuIcon
        sx={{ cursor: 'pointer', color: 'white', fontSize: '28px' }}
        onClick={handleOpenDrawer}
      />
    )}
  </DrawerHeaderStack>
  <List>
    {listArray.map((link: string, index: number) => (
      ((userRole === 'sales_manager' || userRole === 'admin')|| (userRole === 'sales' && link !== 'addUser')) && (
        <React.Fragment key={`index-${index}`}>
          <ListItem
            onClick={() =>
              !openDrawer
                ? handleOpenDrawer()
                : menuObject[link].length > 0
                ? handleOpenMenu(index, link)
                : handleClickMenu(link)

            }
          >
            <StyledListItemButton selected={pathName?.includes(link)}>
              <ListItemIcon sx={{ minWidth: '35px' }}>
                {getListItemIcon(link)}
              </ListItemIcon>
              {openDrawer && <StyledListItemText primary={t(link)} />}
              {openDrawer &&
                menuObject[link].length > 0 &&
                (openedIndex === index ? (
                  <ArrowDropUpRoundedIcon sx={SmallArrow} />
                ) : (
                  <ArrowDropDownRoundedIcon sx={SmallArrow} />
                ))}
            </StyledListItemButton>
          </ListItem>
          {openDrawer && menuObject[link].length > 0 && (
            <Collapse
              key={`ii-${index}`}
              in={openedIndex === index}
              timeout='auto'
              unmountOnExit
            >
              <ChildList key={`i-${index}`}>
                {menuObject[link].map((subLink: string, j: number) => (
                  <ListItem key={`e-${j}`}>
                    <StyledListItemButton
                      onClick={() => handleClickMenu(`${link}/${subLink}`)}
                      selected={pathName.includes(subLink)}
                    >
                      <StyledListItemText primary={t(subLink)} />
                    </StyledListItemButton>
                  </ListItem>
                ))}
              </ChildList>
            </Collapse>
          )}
        </React.Fragment>
      )
    ))}
  </List>
</StyledDrawer>
</ClickAwayListener>
);
};

export default SideDrawer
