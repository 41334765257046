const AdminFilter = (props: any) => {
  const {
    setEmployeeEmail,
    employeeEmail,
    setIsUserEmployeeEmailValid,
    isUserEmployeeEmailValid,
    setPageChange,
  } = props

  const handleSearch = (e: { target: { value: any } }) => {
    const enteredEmail = e.target.value
    setEmployeeEmail(enteredEmail)
    // setPageChange(1)

    if (enteredEmail.trim() === '') {
      setIsUserEmployeeEmailValid(true)
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      setIsUserEmployeeEmailValid(emailPattern.test(enteredEmail))
    }
  }
  switch ('admin') {
    case 'admin':
      return (
        <div className='stats-inputs'>
          <div className='stats-label'>Employee Email</div>
          <input
            type={'text'}
            value={employeeEmail}
            className={'stats-input'}
            onChange={handleSearch}
          />
          {!isUserEmployeeEmailValid && (
            <div className='error-message'>Please enter a valid email address!</div>
          )}
        </div>
      )
  }
}

export default AdminFilter
