import { Accordion, AccordionSummary, Typography } from '@mui/material'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OuterTableData from './OuterTableData'
import { dashboardEntity } from '../../reducers'
import { getEmailTrackDataAPICall } from '../../actions'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import '../Leads/eta.css'
import Pagination from './Pagination'
import FilterData from './FilterData'
import { TextField } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const TrackEmail = (props: any) => {
  const { getEmailTrackDataAPICall, getEmailTrackData } = props
  const [userSearch, setUserSearch] = useState('')
  const [pageChange, setPageChange] = useState(1)
  const [type, setType] = useState('')
  const [messageId, setMessageId] = useState('')
  const limit = 10
  useEffect(() => {
    const searchData = {
      limit: limit,
      page: pageChange,

      searchString: userSearch,
    }
    getEmailTrackDataAPICall(searchData)
  }, [userSearch, pageChange])

  const handleSearch = (e: { target: { value: any } }) => {
    setPageChange(1)
    setUserSearch(e.target.value)
  }
  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage)
  }
  return (
    <div className='trackemaildash'>
      <div className='dash-head'>
      {/* <div className='search-user-label'>Search User</div> */}
        {/* <input type={'text'} className='search-user-input' onChange={handleSearch} /> */}
        <TextField
          fullWidth
          id="standard-bare"
          variant="outlined"
            // type={'text'}
            // className='search-user-input'
            placeholder='Search User'
            value={userSearch}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ width: '300px',}}
          />
        <span style={{marginLeft:'20%',fontFamily:'Montserrat-SemiBold', fontSize:'20px', fontWeight:'400',color:'rgb(25, 60, 109)'}}>
          Sent Email Details </span>
        {/* <button type={'submit'} className='search-user-submit'>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </button> */}
        </div>
      <div className='mail-receipt'>
        <Accordion
          expanded={false}
          onChange={() => {}}
          sx={{
            margin: '0 auto !important',
            backgroundColor:'rgb(25, 60, 109)'
          }}
        >
          <AccordionSummary
            sx={{
              color: 'whitesmoke',
            }}
          >
            <Typography
              sx={{
                margin: 'auto 15px auto 44px',
                width: '22%',
                fontWeight: '600',
              }}
            >
              <span className='mail-info'>Receiver</span>
            </Typography>
            <Typography
              sx={{
                margin: 'auto 15px auto 8px',

                width: '15%',
                fontWeight: '600',
              }}
            >
              <span className='mail-info'>Time</span>
            </Typography>
            <Typography
              sx={{
                margin: 'auto 15px auto 8px',
                width: '30%',
                fontWeight: '600',
              }}
            >
              <span className='mail-info'>Subject</span>
            </Typography>
            <Typography
              sx={{
                margin: 'auto 0px auto 5px',
                width: '12%',
                fontWeight: '600',
              }}
            >
              <span className='mail-info'>Status</span>
            </Typography>
            <Typography
              sx={{
                width: '12%',
                fontSize:'15px',
                fontWeight: '500',
                fontFamily:'Montserrat-Medium',
              }}
            >
              <FilterData setType={setType} type={type} />
            </Typography>
          </AccordionSummary>
        </Accordion>
        <OuterTableData
          setType={setType}
          messageId={messageId}
          setMessageId={setMessageId}
          type={type}
          limit={limit}
          page={pageChange}
          searchString={userSearch}
        />
        <Pagination
          emailTrackData={getEmailTrackData?.data}
          handleChangePage={handleChangePage}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    getEmailTrackData: dashboardEntity.getDashboard(state).getEmailTrackData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getEmailTrackDataAPICall: (data: any) => dispatch(getEmailTrackDataAPICall.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackEmail)
