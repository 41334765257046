import GetTudipLogo from '../assets/images/favicon.ico'
import GetCRMLogo from '../assets/images/CRMLogo.png'
import GetLoginProfile from '../assets/images/profile.jpg'
import GetHomeIcon from '../assets/images/home.svg'
import GetOpenTabIcon from '../assets/images/open_New_Tab.svg'
import GetDashboardIcon from '../assets/images/dashboard-icon.svg'
import TudipCompensatiopnLogo from '../assets/images/logo.png'
import loginLeftImage from '../assets/images/login_image.png'

export const OpenTabLogo = GetOpenTabIcon
export const CRMLogo = GetCRMLogo
export const TudipLogo = GetTudipLogo
export const CurrentLoginUserProfile = GetLoginProfile
export const HomeIcon = GetHomeIcon
export const DashboardIcon = GetDashboardIcon
export const GetCompensationTudipLogo = TudipCompensatiopnLogo
export const GetLoginLeftImage = loginLeftImage

export const SideDrawerMenu: { [key: string]: string[] } = {
  dashboard: [],
  dealClosed: [],
  contacts:[],
  track_email: [],
  email_dashboard:[],
  analytics:[],
  campaign:[],
  addUser:[],
  userManual:[],
  // employeeportal: ['employeeinfo', 'backgroundinfo', 'employeelist', 'rca'],
}
