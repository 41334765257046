import { Accordion, AccordionSummary, Typography } from '@mui/material'
import { Box, CircularProgress } from '@material-ui/core'

const StatsOuterTable = (props: any) => {
  const { dashboardData } = props

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }

  const setbackground = (i: number) => {
    if (i % 2) {
      return '#F5F5F5'
    } else {
      return '#ffffff'
    }
  }
  const checkData = (data: any) => {
    if (data) {
      return data
    } else {
      return 'NA'
    }
  }

  const checkDate = (date: string | number | Date) => {
    const currentDate = new Date(date).toLocaleString('en-US', options)
    if (currentDate === 'Invalid Date') {
      return 'NA'
    } else {
      return currentDate
    }
  }

  const checkEmailsData = () => {
    if (dashboardData?.length === 0) {
      return 'no-data-available'
    } else {
      return 'data-available'
    }
  }

  switch (checkEmailsData()) {
    case 'no-data-available':
      return <div className='no-data-outer-table'>No mail for this user exists currently.</div>
    case 'data-available':
      return (
        <>
          {dashboardData?.length ? (
            dashboardData?.map((data: any, index: number) => (
              <Accordion
                expanded={false}
                onChange={() => {}}
                sx={{
                  margin: '0 auto !important',
                }}
                key={index}
              >
                <AccordionSummary
                  sx={{
                    background: setbackground(index),
                    color: 'whitesmoke',
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: '5%',
                      width: '33%',
                      height: '25px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize:'12px',
                      fontFamily:'Montserrat-Medium',
                      color:'rgba(0, 0, 0, 0.87)'
                    }}
                  >
                    {checkData(
                      data?.To?.split(',')?.length > 1 ? data?.To?.split(',')[0] + '...' : data?.To,
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      width: '33%',
                      height: '25px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize:'12px',
                      fontFamily:'Montserrat-Medium',
                      color:'rgba(0, 0, 0, 0.87)'
                    }}
                  >
                    {data?.SubmittedAt && (
                      <p>{new Date(data.SubmittedAt).toLocaleString('en-US', options)}</p>
                    )}
                    {/* {checkDate(data.SubmittedAt)} */}
                  </Typography>
                  <Typography
                    sx={{
                      width: '33%',
                      height: '25px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize:'12px',
                      fontFamily:'Montserrat-Medium',
                      color:'rgba(0, 0, 0, 0.87)'
                    }}
                  >
                    {checkData(data?.Subject)?.length > 50
                      ? (data?.Subject).slice(0, 50) + '...'
                      : data?.Subject}
                  </Typography>
                </AccordionSummary>
              </Accordion>
            ))
          ) : (
            <Box className='loaderParent'>
              <CircularProgress color='primary' />
            </Box>
          )}
        </>
      )
  }
}

export default StatsOuterTable
