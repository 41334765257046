import { LoginAuthData } from '../components/Types'
import * as RestAPI from './rest'

export const loginUserEndpoint = (data: LoginAuthData) =>
  RestAPI.POST(`/google-authentication`, data)

export const getLoginUser = (data: any) => RestAPI.POST('user/login/user', data)

export const getLeadsDataEndPoint = (data: any) =>
  RestAPI.GET(
    `lead?page=${data?.page ? data?.page : 1}&limit= ${
      data?.limit ? data?.limit : 10
    }&searchString=${data?.searchString ? data?.searchString : ''}&follow_up_status=${data.follow_up_status}&userIds=${data.userIds}&country=${data.country}&domain=${data.domain}&source=${data.source ? data.source : ""}&temperature=${data.temperature ? data.temperature : ""}&fromDate=${data.fromDate}&toDate=${data.toDate}&isFromHome=${data.isFromHome},`,
  )

export const getAnalyticsDataEndPoint = (data: any) =>
    RestAPI.GET(
      `lead/status/count?fromDate=${data.fromDate}&toDate=${data.toDate}&follow_up_status=1st call done`,
    )

export const getAnalyticsBarDataEndPoint = (data: any) =>
      RestAPI.GET(
        `lead/particular/data?fromDate=${data.fromDate}&toDate=${data.toDate}&id=${data.id}`,
      )

export const getTargetGaugeDataEndPoint = (data: any) =>
        RestAPI.GET(
          `task/speedometer/data?startDate=${data.startDate}&endDate=${data.endDate}`,
        )

export const getTemperaturePieChartDataEndPoint = (data: any) => RestAPI.GET(`deal/tempreture/analytic?id=${data.id}&fromDate=${data.fromDate}&toDate=${data.toDate}`)

export const getAnalyticsNameDataEndPoint = (data: any) => RestAPI.GET(`lead/leads/names`,data)

export const setLeadsColumnDataEndPoint = (data: any) => RestAPI.POST('lead/setPreference', data)

export const getLeadsStatusData = (data: any) => RestAPI.GET(`lead/dropDown/data?fromDate=${data.fromDate}&toDate=${data.toDate}`)  

export const getLeadsDetailsById = (data: any) => RestAPI.GET(`lead/${data}`,)

export const getDownloadableLeadFileURL = (data: any) => RestAPI.GET(`lead/leadFile/data`,data)

export const getEmail = (data: any) => RestAPI.POST('email', data.sendEmail)

export const CampaignSendEmail = (data: any) => RestAPI.POST1('email/bulk/email', data.sendEmail)

export const AddCampaignData = (data: any) => RestAPI.POST('campaign/campaigns', data)

export const getCampaignData = (data: any) => RestAPI.GET(`campaign/?page=${data.page}&limit=${data.limit}`)

export const getCampaignMailListData = (data: any) => RestAPI.GET(`campaign/campaignsMail/data?name=${data.Name}&usersId=${data.UsersId}&campaignId=${data.campaignId}&email=${data.email}`)

export const getCampaignDetailsData = (data: any) => RestAPI.GET(`campaign/campaigns?id=${data.id}&search=${data.search || ""}&page=${data.page}&pageSize=${data.pageSize}`)

export const getCampaignNameData = (data: any) => RestAPI.GET(`campaign/campaign-names`,data)

export const getContactData = (data: any) => RestAPI.GET(`contact?searchString=${data.searchString}&page=${data.pageChange}&limit=${data.limit}`)

export const createContactsData = (data: any) => RestAPI.POST('contact/', data)

export const getContactDetailsData = (data: any) => RestAPI.GET(`contact/${data.id}`)

export const getUploadContactsFile = (data: any) => RestAPI.UploadExcelPOST('lead/upload', data)

export const removeLeadData = (data: any) => RestAPI.PATCH1(`lead`,data)

export const createUserData = (data: any) => RestAPI.POST('user', data)

export const setTargetData = (data: any) => RestAPI.POST('task/setTarget', data)

export const updateUserData = (data: any) => RestAPI.PATCH('user/data', data)

export const changePasswordData = (data: any) => RestAPI.PATCH('user/data', data)

export const getAddUserDataEndPoint = (data: any) => RestAPI.GET(`user`,data)

export const getManagerDataEndPoint = (data: any) => RestAPI.GET(`user/managers/name`,data)  

export const getEventCalendarDataEndPoint = (data: any) => RestAPI.GET(`followUp/getFollowUpByDate/calendar?year=${data.year}&month=${data.month}`)  

export const getUploadFile = (data: any) => RestAPI.UploadExcelPOST('lead/upload', data)


export const postBulkEmail = (data: any) => RestAPI.POST1('email/bulk/email', data.sendEmail)

export const getFollowUpDataById = (data: any) =>
  RestAPI.GET(
    `followUp/${data.email}`,
  )

export const updateFollowUpData = (data: any) => 
  RestAPI.PUT(
    `followUp/${data.id}`, data
  )

  export const createFollowUpData = (data: any) => 
    RestAPI.POST(`followUp/`, data)
  
  
  export const createLeadData = (data: any) => 
    RestAPI.POST(`lead`, data)

  export const updateLeadData = (data: any) => RestAPI.PATCH1(`lead`,data)

  export const getEmailTrackData = (data: any) =>
    RestAPI.GET(
      `email/emails/personal?searchString=${data.searchString}&page=${data.page}&limit=${data.limit}&type=${data.type}`,
    )

  export const getEmailDetailsData = (data: any) =>
    RestAPI.GET(`email/webmessage/${data.id}?type=${data.type}`)
  
  export const getEmailDashboardData = (data: any) =>
    RestAPI.GET(
      `email/emails/all?employeeString=${data.employeeString}&userString=${data.userString}&page=${
        data.page
      }&limit=${data.limit}&to=${data.to ? data.to : ''}&from=${data.from ? data.from : ''}`,
    )
  export const getEmailGraphData = (data: any) =>
    RestAPI.GET(
      `email/statistics/dashboard?employeeString=${data.employeeString}&userString=${
        data.userString
      }&to=${data.to ? data.to : ''}&from=${data.from ? data.from : ''}`,
    )
