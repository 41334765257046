import { connect } from 'react-redux'
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TableInnerData from './TableInnerData'
import { Key, useEffect, useState } from 'react'
import FilterData from './FilterData'
import { dashboardEntity } from '../../reducers'
import { getEmailDetailsDataAPICall, getEmailTrackDataAPICall } from '../../actions'
import { Box, CircularProgress, Dialog } from '@material-ui/core'

const OuterTableStatus = (props: any) => {
  const { emails, getEmailDetailsDataAPICall, getEmailTrackDataAPICall, getEmailDetailsData, type, limit, page, searchString } = props
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)
  const [messageId, setMessageId] = useState('')
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }

  useEffect(() => {
    const searchData = {
      limit: limit,
      page: page,
      type: type,
      searchString: searchString,
    }
    getEmailTrackDataAPICall(searchData)
  }, [type])

  useEffect(() => {
    let data = {
      id: messageId,
      type: type,
    }
    if(messageId){
    getEmailDetailsDataAPICall(data)
  }
  }, [messageId])
  const handleAccordionChange = (index: number, data: any) => {
    setMessageId(data?.MessageID)
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const setbackground = (i: any) => {
    if (i % 2) {
      return '#F5F5F5'
    } else {
      return '#ffffff'
    }
  }

  const checkfilteredData = () => {
    if (emails?.length === 0) {
      return 'data-not-available'
    } else {
      return 'data-available'
    }
  }
  switch (checkfilteredData()) {
    case 'data-not-available':
      return <div className='no-data-outer-table'>No mail for this user exists on this page.</div>
    case 'data-available':
      return (
        <>
          {emails?.length ? (
            emails?.map((data: any, index: Key | null | undefined) => (
              <Accordion
                expanded={expandedIndex === index}
                sx={{
                  margin: '0 auto !important',
                }}
                key={index}
                onClick={() => handleAccordionChange(index as number, data)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ cursor: 'pointer', color: '#000000' }} />}
                  sx={{
                    background: setbackground(index),
                    color: 'whitesmoke',
                    margin:'0'
                  }}
                >
                  <Typography
                    sx={{
                      width: '22.5%',
                      height: '25px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      margin: '0px 15px 0px 45px',
                      color:'rgba(0, 0, 0, 0.87)',
                      fontFamily:'Montserrat-Medium',
                      fontSize:'12px'
                    }}
                  >
                    {data?.To?.split(',')?.length > 1 ? data?.To?.split(',')[0] + '...' : data?.To}
                  </Typography>
                  <Typography
                    sx={{
                      width: '15.5%',
                      height: '25px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      margin: '-10px 15px 0px 5px!important',
                      color:'rgba(0, 0, 0, 0.87)',
                      fontFamily:'Montserrat-Medium',
                      fontSize:'12px'
                    }}
                  >
                    {data?.SubmittedAt && (
                      <p>{new Date(data.SubmittedAt).toLocaleString('en-US', options)}</p>
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      width: '30.5%',
                      height: '25px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      margin: '0px 15px 0px 5px',
                      color:'rgba(0, 0, 0, 0.87)',
                      fontFamily:'Montserrat-Medium',
                      fontSize:'12px'
                    }}
                  >
                    {data?.Subject?.length > 50
                      ? (data?.Subject).slice(0, 50) + '...'
                      : data?.Subject}
                  </Typography>
                  <Typography
                    sx={{
                      width: '14.5%',
                      height: '25px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      margin: '0px 15px 0px 5px',
                      color:'rgba(0, 0, 0, 0.87)',
                      fontFamily:'Montserrat-Medium',
                      fontSize:'12px'
                    }}
                  >
                    {data?.recent_status?.length > 23
                      ? (data?.recent_status).slice(0, 23) + '...'
                      : data?.recent_status === 'SubscriptionChange'
                      ? 'Subscription Change'
                      : data?.recent_status}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor:'#F5F5F5', border:'1px solid #454545'}}>
                  <TableInnerData innerData={getEmailDetailsData?.data} />
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box className='loaderParent'>
              <CircularProgress color='primary' />
            </Box>
          )}
        </>
      )
  }
}

const mapStateToProps = (state: any) => {
  return {
    getEmailDetailsData: dashboardEntity.getDashboard(state).getEmailDetailsData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getEmailTrackDataAPICall: (data: any) => dispatch(getEmailTrackDataAPICall.request(data)),
    getEmailDetailsDataAPICall: (data: any) => dispatch(getEmailDetailsDataAPICall.request(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OuterTableStatus)
