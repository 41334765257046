import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './statics.css'
import { Accordion, AccordionSummary, Box, Typography } from '@mui/material'
import StatsFilters from './StatsFilters'
import { dashboardEntity } from '../../reducers'
import { getEmailDashboardAPICall } from '../../actions'
import StatsOuterTable from './StatsOuterTable'
import StatsPagination from './StatsPagination'
import DateFilter from './DateFilter'

const Statics = (props: any) => {
  const { getEmailDashboardAPICall, getEmailDashboardData } = props
  const [pageChange, setPageChange] = useState(1)
  const [employeeEmail, setEmployeeEmail] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const limit = 10
  const [searchPage, setSearchPage] = useState(0)
  const [dateStart, setDateStart] = useState(null)
  const [dateEnd, setDateEnd] = useState(null)

  useEffect(() => {
    const data = {
      employeeString: employeeEmail,
      userString: userEmail,
      page: pageChange,
      limit: limit,
      to: dateEnd,
      from: dateStart,
    }

    getEmailDashboardAPICall(data)  
  }, [pageChange])

  const handleChangePage = (event: any, newPage: any) => {
    setPageChange(newPage)
  }
  const handleSearchPagination = () => {
    const data = {
      employeeString: employeeEmail,
      userString: userEmail,
      page: searchPage,
      limit: limit,
      to: dateEnd,
      from: dateStart,
    }

    getEmailDashboardAPICall(data)
  }

  return (
    <div className='stats-body'>
      <div className='stats-head'>Stats</div>
      <DateFilter
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
      />
      <StatsFilters
        employeeEmail={employeeEmail}
        setEmployeeEmail={setEmployeeEmail}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        pageChange={pageChange}
        limit={limit}
        setPageChange={setPageChange}
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
      />
    
      <div className='stats-head'>
        <div className='stats-head-details'>{'Mail Details'}</div>
      </div>
      <div className='stats-table'>
        <Accordion
          expanded={false}
          onChange={() => {}}
          sx={{
            margin: '0 auto !important',
            backgroundColor:'rgb(25, 60, 109)'
          }}
        >
          <AccordionSummary
            sx={{
              color: 'whitesmoke',
            }}
          >
            <Typography
              sx={{
                marginLeft: '5%',
                width: '33%',
                fontWeight: '600',
              }}
            >
              <span className='mail-info'>Receiver</span>
            </Typography>
            <Typography
              sx={{
                width: '33%',
                fontWeight: '600',
              }}
            >
              <span className='mail-info'>Time</span>
            </Typography>
            <Typography
              sx={{
                width: '33%',
                fontWeight: '600',
              }}
            >
              <span className='mail-info'>Subject</span>
            </Typography>
          </AccordionSummary>
        </Accordion>
        <StatsOuterTable dashboardData={getEmailDashboardData?.data?.emails} />

        <StatsPagination
          handleChangePage={handleChangePage}
          getEmailDashboardData={getEmailDashboardData?.data}
          pageChange={pageChange}
          setSearchPage={setSearchPage}
          handleSearchPagination={handleSearchPagination}
        />
      </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    getEmailDashboardData: dashboardEntity.getDashboard(state).getEmailDashboardData,
  }
}
const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getEmailDashboardAPICall: (data: any) => dispatch(getEmailDashboardAPICall.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Statics)
