import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'

const FilterData = (props: any) => {
  const { expandedIndex, index, setType, type } = props
  const [expanded, setExpanded] = useState(false)
  const showFilter = () => {
    if (expandedIndex === index) {
      return 'show-filter'
    } else {
      return 'hide-filter'
    }
  }

  const handleTypeChange = (type: string) => {
    setExpanded(false)
    setType(type)
  }

  const setBackground = (data: string) => {
    if (data === type) {
      return '#677fad'
    }
  }

  const setColor = (data: string) => {
    if (data === type) {
      return 'white'
    }
  }
  switch (showFilter()) {
    case 'hide-filter':
      return <div className='show-filter'></div>
    case 'show-filter':
      return (
        <div className='show-filter'>
          <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            sx={{
              // width: '170px',
              margin: '0 auto !important',
              borderRadius: '5px !important',
              color: '#193c6c',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                // width: '170px',
                borderRadius: `5px 5px `,
                border: '2px solid #193c6c',
              }}
            >
              {type === ''
                ? 'ALL'
                : type === 'Open'
                ? 'OPENED'
                : type === 'Delivery'
                ? 'DELIVERED'
                : type === 'Bounce'
                ? 'BOUNCED'
                : type === 'SpamComplaint'
                ? 'SPAMMED'
                : type === 'Click'
                ? 'CLICKED'
                : type === 'SubscriptionChange'
                ? ' SUB CHANGED'
                : ''}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                position: 'absolute',
                zIndex: '10',
                background: 'white',
                width: '100%',
                margin: '0',
                padding: '0',
                color: '#193c6c',
                boxShadow: '2px 2px 6px grey',
                borderRadius: '0 0 5px 5px',
              }}
            >
              <Typography
                sx={{
                  padding: '5px 10px',
                  // border: '2px solid #193c6c',
                  textAlign: 'center',
                  background: setBackground(''),
                  color: setColor(''),
                  fontSize:'14px',
                  fontFamily:'Montserrat-Medium'
                }}
              >
                <div className='filter-opt' onClick={() => handleTypeChange('')}>
                  ALL
                </div>
              </Typography>
              <Typography
                sx={{
                  padding: '5px 10px',
                  // border: '2px solid #193c6c',
                  textAlign: 'center',
                  background: setBackground('Open'),
                  color: setColor('Open'),
                  fontSize:'14px',
                  fontFamily:'Montserrat-Medium'
                }}
              >
                <div className='filter-opt' onClick={() => handleTypeChange('Open')}>
                  OPENED
                </div>
              </Typography>
              <Typography
                sx={{
                  padding: '5px 10px',
                  // border: '2px solid #193c6c',
                  textAlign: 'center',
                  background: setBackground('Delivery'),
                  color: setColor('Delivery'),
                  fontSize:'14px',
                  fontFamily:'Montserrat-Medium'
                }}
              >
                <div className='filter-opt' onClick={() => handleTypeChange('Delivery')}>
                  DELIVERED
                </div>
              </Typography>
              <Typography
                sx={{
                  padding: '5px 10px',
                  // border: '2px solid #193c6c',
                  textAlign: 'center',
                  background: setBackground('Click'),
                  color: setColor('Click'),
                  fontSize:'14px',
                  fontFamily:'Montserrat-Medium'
                }}
              >
                <div className='filter-opt' onClick={() => handleTypeChange('Click')}>
                  CLICKED
                </div>
              </Typography>
              <Typography
                sx={{
                  padding: '5px 10px',
                  // border: '2px solid #193c6c',
                  textAlign: 'center',
                  background: setBackground('Bounce'),
                  color: setColor('Bounce'),
                  fontSize:'14px',
                  fontFamily:'Montserrat-Medium'
                }}
              >
                <div className='filter-opt' onClick={() => handleTypeChange('Bounce')}>
                  BOUNCED
                </div>
              </Typography>
              <Typography
                sx={{
                  padding: '5px 10px',
                  // border: '2px solid #193c6c',
                  textAlign: 'center',
                  background: setBackground('SpamComplaint'),
                  color: setColor('SpamComplaint'),
                  fontSize:'14px',
                  fontFamily:'Montserrat-Medium'
                }}
              >
                <div className='filter-opt' onClick={() => handleTypeChange('SpamComplaint')}>
                  SPAMMED
                </div>
              </Typography>
              <Typography
                sx={{
                  padding: '5px 10px',
                  // border: '2px solid #193c6c',
                  textAlign: 'center',
                  borderRadius: '0 0 5px 5px',
                  background: setBackground('SubscriptionChange'),
                  color: setColor('SubscriptionChange'),
                  fontSize:'14px',
                  fontFamily:'Montserrat-Medium'
                }}
              >
                <div className='filter-opt' onClick={() => handleTypeChange('SubscriptionChange')}>
                  SUB CHANGED
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      )
  }
}

export default FilterData
