export const statusOptions = [
  "1st call done",
  "1st email sent",
  "2nd email sent",
  "3rd email sent",
  "Connect Later",
  "Dormant",
  "Lost",
  "Negotiation",
  "New",
  "Proposal sent",
  "Proposal/POC Requested",
  "Proposal/POC Submitted",
  "Unsubscribe",
  "Won"
];

export const domainOptions = [
  'Automotive',
  'Aviation',
  'Banking & Finance' ,
  'Construction' ,
  'E-Learning' ,
  'FMCG' ,
  'HealthCare' ,
  'Hospitality' ,
  'IT' ,
  'Insurance' ,
  'Logistics & SupplyChain' ,
  'Manufacturing/Machinery' ,
  'Oil & Gas' ,
  'OTT/Media' ,
  'Recruitment' ,
  'Telecom' ,
  'Universities',
  'Others',
];

export const sourceOptions = [
  'Apollo',
  'Google',
  'Inbound Lead',
  'LinkedIn',
  'Partnership',
  'Others',
  '2024',
]

export const companySizeOptions = [
  "0-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001+",
]

export const columnMapping = {
  'Phone': 'leadPhone',
  'Owner': 'owner',
  'Country': 'leads_country',
  'Domain': 'domain',
  'Source': 'source',
  'Temperature': 'temperature',
  'Status': 'follow_up_status',
  'Probability': 'probability',
  'Next FollowUp Date': 'followUpsNextDate',
  'Created Date': 'followUpsDate',
};

export const columnDisplayNames: { [key: string]: any } = {
  leadPhone: 'Phone',
  lead_designation: 'Designation',
  owner: 'Owner',
  leads_country: 'Country',
  domain: 'Domain',
  temperature: 'Temperature',
  source: 'Source',
  follow_up_status: 'Status',
  probability: 'Probability',
  followUpsNextDate: 'Next FollowUp Date',
  followUpsDate: 'Created Date',
};