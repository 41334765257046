import { connect } from 'react-redux'
import { dashboardEntity } from '../../reducers'
import OuterTableStatus from './OuterTableStatus'
const OuterTableData = (props: any) => {
  const { getEmailTrackData, setType, expandedIndex, type, messageId, setMessageId, limit, page, searchString } = props
  const checkTableData = () => {
    if (getEmailTrackData?.data?.emails?.length < 1) {
      return 'data-not-available'
    } else {
      return 'data-available'
    }
  }
  switch (checkTableData()) {
    case 'data-not-available':
      return <div className='no-data-outer-table'>You haven't sent any mail yet.</div>

    case 'data-available':
      return (
        <OuterTableStatus
          messageId={messageId}
          setMessageId={setMessageId}
          emails={getEmailTrackData?.data?.emails}
          setType={setType}
          expandedIndex={expandedIndex}
          type={type}
          limit={limit}
          page={page}
          searchString={searchString}
        />
      )
  }
}

const mapStateToProps = (state: any) => {
  return {
    getEmailTrackData: dashboardEntity.getDashboard(state).getEmailTrackData,
  }
}

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(OuterTableData)
