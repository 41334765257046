import { Box, createStyles, makeStyles } from '@material-ui/core'
import NewTrackEmail from '../components/TrackEmail/TrackEmail'

const useStyles = makeStyles(() => {
  return createStyles({
    mainContainer: {
      height: '95%',
      width: '100%',
    },
  })
})

const TrackEmail = () => {
  const classes = useStyles()
  return (
    <>
      <Box className='pages-container'>
        <NewTrackEmail />
      </Box>
    </>
  )
}

export default TrackEmail
