export const searchBar ={
    '& label.Mui-focused': {
        color: '#666666',
      },
      '& .MuiFormControl-root': {
        width: "0px !important",
      },
      '& .MuiOutlinedInput-root': {
        width:'280px',
        height:'35px',
        borderRadius: '20px',
        backgroundColor:'#ffffff',
        '&.Mui-focused fieldset': {
          borderColor: '#fff',
          borderRadius: '20px',
        },
        '& fieldset': {
          borderColor: '#193c6d !important',
          borderRadius: '20px',
          boxShadow: 1,
        },
        '&:hover fieldset': {
          borderColor: '#fff',
        },
        '& .MuiInputBase-input': {
          borderRadius: '20px',
          fontFamily: 'Montserrat-Medium',
          marginLeft: 3,
          letterSpacing: '0',
        },
      },
      svg: {
        color: '#666666',
      },
}