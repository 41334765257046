import { Pagination as MuiPagination } from '@mui/material'
import Stack from '@mui/material/Stack'

const StatsPagination = (props: any) => {
  const { getEmailDashboardData, handleChangePage, pageChange } = props
  const checkPagination = () => {
    if (getEmailDashboardData?.pages > 0) {
      return 'pagination-required'
    } else {
      return 'pagination-not-required'
    }
  }
  switch (checkPagination()) {
    case 'pagination-not-required':
      return <></>
    case 'pagination-required':
      return (
        <div className='page-cont'>
          <div className='page-items'>
            <Stack>
              <MuiPagination
                count={getEmailDashboardData?.pages}
                variant='outlined'
                color='primary'
                page={pageChange}
                onChange={handleChangePage}
              />
            </Stack>
          </div>
        </div>
      )
  }
}

export default StatsPagination
