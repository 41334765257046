import {
  Button,
  Grid,
  MenuItem,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import { connect } from "react-redux";
import {
  createContactsDataAPICall,
} from "../../actions";
import { dashboardEntity } from "../../reducers";
import countriesData from "../Leads/CountriesandStates.json";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiFormLabel-asterisk": {
    color: "red",
  },
}));

const CreateContact = (props: any) => {
  const { 
    handleCreateContactDialogClose,
    createContactsDataAPICall, 
} = props;

  const [states, setStates] = useState<string[]>([]);

  const useStyles = makeStyles(() => {
    return createStyles({
      addUser: {
        borderRadius: "20px",
        fontFamily: "Montserrat-SemiBold",
        fontSize: "14px",
      },
      disabled: {
        cursor: "not-allowed",
      },
      datepicker1: {
        width: "425px",
        borderRadius: "20px",
      },
      datepicker2: {
        width: "418px",
        borderRadius: "20px",
      },
      cancelbtn: {
        borderRadius: "20px!important",
        backgroundColor: "#e0e0e0!important",
        color: "#000000!important",
        marginRight: "10px!important",
        fontFamily: "Montserrat-SemiBold!important",
        fontSize: "14px!important",
        width: "100px!important",
      },
      button: {
        borderRadius: "20px !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
        backgroundColor: "#193C6D !important",
        color: "#ffffff !important",
        "&.Mui-disabled": {
          opacity: 0.5,
          color: "#ffffff",
        },
      },
      datepickercontainer: {
        display: "flex",
        alignItems: "center",
        // backgroundColor: "#add8e6",
        width: "99%",
        height: "55px",
      },
      subheading: {
        margin: "0 0 0 4% !important",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "14px !important",
      },
      actionbtn: {
        height: "20px !important",
        width: "20px !important",
      },
      headingtxt: {
        color: "#000000",
        fontFamily: "Montserrat-SemiBold !important",
        fontSize: "22px!important",
      },
      addLead: {
        cursor: "pointer!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center!important",
        height: "40px",
        textTransform: "capitalize",
        marginRight: "0px !important",
        border: "0",
        color: "#fff !important",
        background: "rgb(25, 60, 109)",
        borderRadius: "20px !important",
        fontFamily: "Montserrat-Medium !important",
        fontSize: "14px !important",
        width: "10% !important",
        wordBreak: "break-word",
      },
    });
  });

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      status: "",
      phone: "",
      mobile:"",
      email:"",
      secondaryemail:"",
      title:"",
      department:"",
      accountName:"",
      assistant:"",
      assistantPhone:"",
      linkedIn:"",
      instagram:"",
      country: "",
      state: "",
      city:"",
      zip:"",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstname: Yup.string().required("First Name is required"),
      lastname: Yup.string().required("Last Name is required"),
      country: Yup.string(),
      state: Yup.string(),
      title: Yup.string(),
      department: Yup.string(),
      accountName: Yup.string(),
      assistant: Yup.string(),
      linkedIn: Yup.string(),
      instagram: Yup.string(),
      phone: Yup.number()
        .typeError("Phone must be a number")
        .min(0, "Phone must be a positive number"),
        mobile: Yup.number()
        .typeError("Mobile must be a number")
        .min(0, "Mobile must be a positive number"),
        assistantPhone: Yup.number()
        .typeError("Assistant Phone must be a number")
        .min(0, "Assistant Phone must be a positive number"),
        zip: Yup.number()
        .typeError("Zip must be a number")
        .min(0, "Zip must be a positive number"),
        email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
        secondaryemail: Yup.string()
        .email("Invalid email"),
    }),
    onSubmit: (values, event: any) => {},
  });

  const handleSubmit = () => {
    let Userdata = {
      first_name: formik.values?.firstname,
      last_name: formik.values?.lastname,
      email: formik.values?.email,
      secondary_email: formik.values?.secondaryemail, 
      leadPhone: formik.values?.phone,
      mobile:formik.values?.mobile,
      title: formik.values?.title,
      department: formik.values?.department,
      assistant: formik.values?.assistant,
      assistant_phone: formik.values?.assistantPhone,
      lead_linkedIn_url: formik.values?.linkedIn,
      leads_instaId: formik.values?.instagram,
      country: formik.values?.country,
      state: formik.values?.state,
      city: formik.values?.city,
      zip_code: formik.values?.zip,
      description_of_lead: formik.values?.description,
    };
    if (Userdata) {
      createContactsDataAPICall(Userdata);
      handleCreateContactDialogClose();
    }
  };

  useEffect(() => {
    const selectedCountryData = countriesData.find(
      (country) => country.name === formik.values.country
    );
    const stateNames =
      selectedCountryData?.states.map((state) => state.name) || [];
    setStates(stateNames);
  }, [formik.values.country]);

  const getErrorText = (error: any): string | undefined => {
    return typeof error === "string" ? error : undefined;
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="firstname"
                name="firstname"
                label="First Name"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                helperText={
                  formik.touched.firstname ? getErrorText(formik.errors.firstname) : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="lastname"
                name="lastname"
                label="Last Name"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                helperText={
                  formik.touched.lastname ? getErrorText(formik.errors.lastname) : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="phone"
                name="phone"
                label="Phone"
                required={false}
                sx={{ marginTop: "0", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.phone &&
                  Boolean(formik.errors.phone)
                }
                helperText={
                  formik.touched.phone
                    ? getErrorText(formik.errors.phone)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="mobile"
                name="mobile"
                label="Mobile"
                required={false}
                sx={{ marginTop: "0", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.mobile &&
                  Boolean(formik.errors.mobile)
                }
                helperText={
                  formik.touched.mobile
                    ? getErrorText(formik.errors.mobile)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.email && Boolean(formik.errors.email)
                }
                helperText={
                  formik.touched.email
                    ? getErrorText(formik.errors.email)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="secondaryemail"
                name="secondaryemail"
                label="Secondary Email"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.secondaryemail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.secondaryemail && Boolean(formik.errors.secondaryemail)
                }
                helperText={
                  formik.touched.secondaryemail
                    ? getErrorText(formik.errors.secondaryemail)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="title"
                name="title"
                label="Title"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.title && Boolean(formik.errors.title)
                }
                helperText={
                  formik.touched.title
                    ? getErrorText(formik.errors.title)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="department"
                name="department"
                label="Department"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.department}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.department && Boolean(formik.errors.department)
                }
                helperText={
                  formik.touched.department
                    ? getErrorText(formik.errors.department)
                    : ""
                }
              />
            </div>
          </Grid>
          {/* <Grid item xs={6}>
            <div style={{ marginTop: "5px" }}>
              <StyledTextField
                fullWidth
                id="accountName"
                name="accountName"
                label="Account Name"
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{
                  sx: { borderRadius: 20 },
                }}
                value={formik.values.accountName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.accountName && Boolean(formik.errors.accountName)
                }
                helperText={
                  formik.touched.accountName
                    ? getErrorText(formik.errors.accountName)
                    : ""
                }
              />
            </div>
          </Grid> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="assistant"
                name="assistant"
                label="Assistant"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.assistant}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.assistant &&
                  Boolean(formik.errors.assistant)
                }
                helperText={
                  formik.touched.assistant
                    ? getErrorText(formik.errors.assistant)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="assistantPhone"
                name="assistantPhone"
                label="Assistant Phone"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.assistantPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.assistantPhone &&
                  Boolean(formik.errors.assistantPhone)
                }
                helperText={
                  formik.touched.assistantPhone
                    ? getErrorText(formik.errors.assistantPhone)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="linkedIn"
                name="linkedIn"
                label="LinkedIn"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.linkedIn}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.linkedIn &&
                  Boolean(formik.errors.linkedIn)
                }
                helperText={
                  formik.touched.linkedIn
                    ? getErrorText(formik.errors.linkedIn)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="instagram"
                name="instagram"
                label="Instagram"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.instagram}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.instagram &&
                  Boolean(formik.errors.instagram)
                }
                helperText={
                  formik.touched.instagram
                    ? getErrorText(formik.errors.instagram)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="country"
                name="country"
                label="Country"
                select
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "250px",
                      },
                    },
                  },
                }}
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={
                  formik.touched.country
                    ? getErrorText(formik.errors.country)
                    : ""
                }
              >
                {countriesData
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((country, index) => (
                    <MenuItem key={index} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="state"
                name="state"
                label="State"
                select
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.state &&
                  Boolean(formik.errors.state)
                }
                helperText={
                  formik.touched.state
                    ? getErrorText(formik.errors.state)
                    : ""
                }
              >
                {states.map((state: any, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.city &&
                  Boolean(formik.errors.city)
                }
                helperText={
                  formik.touched.city
                    ? getErrorText(formik.errors.city)
                    : ""
                }
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: "5px" }}>
              <TextField
                fullWidth
                id="zip"
                name="zip"
                label="Zip"
                required={false}
                sx={{ marginTop: "0px", marginBottom: "15px" }}
                InputLabelProps={{ style: { top: "-6px" } }}
                InputProps={{ sx: { borderRadius: 20 } }}
                value={formik.values.zip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.zip &&
                  Boolean(formik.errors.zip)
                }
                helperText={
                  formik.touched.zip
                    ? getErrorText(formik.errors.zip)
                    : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: "5px" }}>
            <TextField
              fullWidth
              multiline
              id="description"
              name="description"
              label="Description"
              required={false}
              sx={{ marginTop: "0px", marginBottom: "15px" }}
              InputProps={{ sx: { padding: "0", borderRadius: 4 } }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description
                  ? getErrorText(formik.errors.description)
                  : ""
              }
            />
          </div>
        </Grid>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            className={classes.cancelbtn}
            // variant='contained'
            color="primary"
            onClick={() => handleCreateContactDialogClose()}
          >
            Cancel
          </Button>
          <span style={{ cursor: "not-allowed" }}>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </span>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    getAddUserData: dashboardEntity.getDashboard(state).getAddUserData,
    getManagerNameData: dashboardEntity.getDashboard(state).getManagerData,
  };
};

const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    createContactsDataAPICall: (data: any) =>
      dispatch(createContactsDataAPICall.request(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateContact);
