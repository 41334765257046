import axios from 'axios'
import { isLoggedIn } from '../auth/Auth'
import { AuthBaseURL, AuthObject, } from '../utils'

// const apiURL = 'http://192.168.248.150:4010/api/';
// const apiURL = 'https://crm-api.tudip.uk/api/';
const apiURL = 'https://learners-api.tudip.com/api/';

const apiPlatform = axios.create({
  baseURL: apiURL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    ...(isLoggedIn() && { Authorization: `Bearer ${isLoggedIn()}` }),
  },
})
const apiPlatform1 = axios.create({
  baseURL: apiURL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})
// multipart/form-data

export const addAuthToken = () => {
  apiPlatform.defaults.headers['authorization'] = `Bearer ${isLoggedIn()}`
  apiPlatform1.defaults.headers['authorization'] = `Bearer ${isLoggedIn()}`
}

//All API calls
export const GET = async (url: string, params = {}) => {
  const response = await apiPlatform.get(url, { params })
  return response
}

export const POST = async (url: string, data: object) => {
  const response = await apiPlatform.post(url, data)
  return response
}

export const POST1 = async (url: string, data: object) => {
  const response = await apiPlatform1.post(url, data)
  return response
}

export const PUT = async (url: string, data: object) => {
  const response = await apiPlatform.put(url, data)
  return response
}

export const DELETE = async (url: string) => {
  const response = await apiPlatform.delete(url)
  return response
}

export const PATCH = async (url: string, data: object) => {
  const response = await apiPlatform.patch(url, data)
  return response
}

export const PATCH1 = async (url: string, data: object) => {
  const response = await apiPlatform1.patch(url, data)
  return response
}

//Auth API calls
const apiOauth = axios.create({
  baseURL: AuthBaseURL,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded',
    // 'Acess-Control-Allow-Origin': '*',
  },
})

//This post will include auth details with the api call
export const OAuthPOST = async (url: string, data: any) => {
  const response = await apiOauth.post(url, new URLSearchParams(data).toString(), {
    auth: AuthObject,
  })
  return response
}

export const OAuthLogout = async (url: string) => {
  // const token = isLoggedIn()
  const response = await apiOauth.post(url, null, {
    headers: {
      // Authorization: `Bearer ${token}`,
    },
  })
  return response
}

// const apiUploadImage = axios.create({
//   baseURL: 'https://reqres.in/api/',
//   headers: {
//     'Content-Type': 'multipart/form-data',
//     'Acess-Control-Allow-Origin': '*',
//     Authorization: `Bearer ${isLoggedIn()}`,
//   },
// })

export const UploadExcelPOST = async (url: string, data: any) => {
  const formData = new FormData()
  formData.append('File', data.File)
  formData.append('role', data.role)
  const response = await apiPlatform1.post(url, formData)
  return response
}

const apiOauthJSON = axios.create({
  baseURL: AuthBaseURL,
  headers: {
    // 'Content-Type': 'application/JSON',
    // 'Acess-Control-Allow-Origin': '*',
    // Authorization: `Bearer ${isLoggedIn()}`,
  },
})

export const addOAuthJSONToken = () => {
  // apiOauthJSON.defaults.headers['authorization'] = `Bearer ${isLoggedIn()}`
}

export const OauthJSONGET = async (url: string, params: object) => {
  const response = await apiOauthJSON.get(url, { params })
  return response
}
