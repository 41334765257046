import { Typography } from '@mui/material'
import InnerTableDataStatus from './InnerTableDataStatus'

const TableInnerData = (props: any) => {
  const { innerData } = props
  const checkInnerData = () => {
    if (innerData?.length === 0) {
      return 'no-data'
    } else {
      return 'data-exists'
    }
  }

  switch (checkInnerData()) {
    case 'data-exists':
      return (
        <>
          <Typography
            sx={{
              display: 'flex',
              margin: '20px 0',
            }}
          >
            <div style={{fontFamily:'Montserrat-Medium', fontSize:'14px', width:'21%', wordWrap:'break-word', textAlign:'center', color:'rgb(25, 60, 109)'}}>Status</div>
            <div style={{fontFamily:'Montserrat-Medium', fontSize:'14px', width:'21%', wordWrap:'break-word', textAlign:'center', color:'rgb(25, 60, 109)'}}>Time</div>
            <div style={{fontFamily:'Montserrat-Medium', fontSize:'14px', width:'21%', wordWrap:'break-word', textAlign:'center', color:'rgb(25, 60, 109)'}}>Recipient</div>
            <div style={{fontFamily:'Montserrat-Medium', fontSize:'14px', width:'21%', wordWrap:'break-word', textAlign:'center', color:'rgb(25, 60, 109)'}}>City</div>
            <div style={{fontFamily:'Montserrat-Medium', fontSize:'14px', width:'21%', wordWrap:'break-word', textAlign:'center', color:'rgb(25, 60, 109)'}}>Country</div>
          </Typography>
          <InnerTableDataStatus innerData={innerData} />
        </>
      )
    case 'no-data':
      return (
        <Typography
          sx={{
            display: 'flex',
            margin: '20px 0',
          }}
        >
          <div className='no-data'>No data available</div>
        </Typography>
      )
  }
}

export default TableInnerData
