import { Box, CircularProgress } from '@material-ui/core'
import { Typography } from '@mui/material'

const InnerTableDataStatus = (props: any) => {
  const { innerData } = props
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  }

  const checkFilteredData = () => {
    if (innerData?.length === 0) {
      return 'data-not-available'
    } else {
      return 'data-available'
    }
  }

  switch (checkFilteredData()) {
    case 'data-not-available':
      return <div className='no-data-outer-table'>No mail related to this filter exists yet.</div>
    case 'data-available':
      return (
        <>
          {innerData?.length ? (
            innerData?.map((data: any) => (
              <Typography
                sx={{
                  display: 'flex',
                  margin: '20px 0',
                  
                }}
              >
                <div style={{fontFamily:'Montserrat-Medium', fontSize:'12px', width:'21%', wordWrap:'break-word', textAlign:'center'}}>
                  {data.status === 'Delivery' ? 'Delivered' : data.status}
                </div>
                <div style={{fontFamily:'Montserrat-Medium', fontSize:'12px', width:'21%', wordWrap:'break-word',textAlign:'center'}}>
                  {' '}
                  {data?.time && <p>{new Date(data.time).toLocaleString('en-US', options)}</p>}
                </div>
                <div style={{fontFamily:'Montserrat-Medium', fontSize:'12px', width:'21%', wordWrap:'break-word',textAlign:'center'}}>
                  {data.recipient}</div>
                <div style={{fontFamily:'Montserrat-Medium', fontSize:'12px', width:'21%', wordWrap:'break-word',textAlign:'center'}}>
                  {data.metaData && data.metaData.Geo && data.metaData.Geo.City}</div>
                <div style={{fontFamily:'Montserrat-Medium', fontSize:'12px', width:'21%', wordWrap:'break-word',textAlign:'center'}}>
                  {data.metaData && data.metaData.Geo && data.metaData.Geo.Country}</div>
              </Typography>
            ))
          ) : (
            <Box className='loaderParent'>
              <CircularProgress color='primary' />
            </Box>
          )}
        </>
      )
  }
}

export default InnerTableDataStatus
