import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactApexChart from 'react-apexcharts'
import AdminFilter from './AdminFilter'
import { getEmailDashboardAPICall, getEmailGraphAPICall } from '../../actions'
import { dashboardEntity } from '../../reducers'
import DateFilter from './DateFilter'

const StatsFilters = (props: any) => {
  const {
    employeeEmail,
    setEmployeeEmail,
    userEmail,
    setUserEmail,
    getEmailGraphAPICall,
    getEmailGraphData,
    getEmailDashboardAPICall,
    pageChange,
    setPageChange,
    limit,
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
  } = props
  const [isUserEmailValid, setIsUserEmailValid] = useState(true)
  const [isUserEmployeeEmailValid, setIsUserEmployeeEmailValid] = useState(true)

  useEffect(() => {
    const data = {
      employeeString: employeeEmail,
      userString: userEmail,
    }
    getEmailGraphAPICall(data)
  }, [])

  const applyFilter = () => {
    handlePageChangeOnSearch()
    const data = {
      employeeString: employeeEmail,
      userString: userEmail,
      page: pageChange,
      limit: limit,
      to: dateEnd,
      from: dateStart,
    }

    getEmailDashboardAPICall(data)
    const GraphData = {
      employeeString: employeeEmail,
      userString: userEmail,
      to: dateEnd,
      from: dateStart,
    }
    getEmailGraphAPICall(GraphData)
  }

  const clearFilter = () => {
    setEmployeeEmail('')
    setUserEmail('')
    setDateEnd(null)
    setDateStart(null)
    const data = {
      employeeString: '',
      userString: '',
      page: pageChange,
      limit: limit,
    }

    getEmailDashboardAPICall(data)
    const GraphData = {
      employeeString: employeeEmail,
      userString: '',
      to: '',
      from: '',
    }
    getEmailGraphAPICall(GraphData)
  }
  const handlePageChangeOnSearch = () => {
    setPageChange(1)
  }

  const options = {
    colors: [
      '#00cd05',
      '#008ffb',
      '#8e8888',
      '#060ecb',
      '#007d27',
      '#ff7505',
      '#ff0505',
      '#ce05ff',
    ],
    labels: ['Opened', 'In Spam', 'Clicked', 'Delivered', 'Bounced', 'Subscription Changed'],
    legend: {
      position: 'bottom' as 'bottom',
    },
  }

  const series: number[] = [
    getEmailGraphData?.totalOpen || 0,
    getEmailGraphData?.totalSpam || 0,
    getEmailGraphData?.totalClick || 0,
    getEmailGraphData?.totalDelivery || 0,
    getEmailGraphData?.totalBounced || 0,
    getEmailGraphData?.TotalSubscriptionChanged || 0,
  ]
  const handleSearch = (e: { target: { value: any } }) => {
    const enteredEmail = e.target.value
    setUserEmail(enteredEmail)

    if (enteredEmail.trim() === '') {
      setIsUserEmailValid(true)
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      setIsUserEmailValid(emailPattern.test(enteredEmail))
    }
  }

  switch ('mail-table') {
    case 'mail-table':
      return (
        <div className='stats-table-head'>
          <div className='stats-filter'>
            {getEmailGraphData?.user?.user_role === 'admin' && (
              <AdminFilter
                employeeEmail={employeeEmail}
                setEmployeeEmail={setEmployeeEmail}
                isUserEmployeeEmailValid={isUserEmployeeEmailValid}
                setIsUserEmployeeEmailValid={setIsUserEmployeeEmailValid}
                setPageChange={setPageChange}
              />
            )}

            <div className='stats-inputs'>
              <div className='stats-label'>User Email</div>
              <input
                type={'text'}
                value={userEmail}
                className={'stats-input'}
                onChange={handleSearch}
              />

              {!isUserEmailValid && (
                <div className='error-message'>Please enter a valid email address!</div>
              )}
            </div>

            <div className='stats-buttons'>
              <button className='stats-button clear-filter' onClick={() => clearFilter()}>
                Clear Filter
              </button>
              <button
                className='stats-button apply-filter'
                disabled={!isUserEmailValid || !isUserEmployeeEmailValid}
                onClick={() => applyFilter()}
              >
                Apply Filter
              </button>
            </div>
          </div>
          <div style={{top:'0',right:'0'}}>
          <ReactApexChart options={options} series={series} type='pie' width={500} />
          </div>
        </div>
      )
    default:
      return null
  }
}

const mapStateToProps = (state: any) => {
  return {
    getEmailGraphData: dashboardEntity.getDashboard(state).getEmailGraphData,
  }
}
const mapDispatchToProps = (dispatch: (arg: { type: string }) => void) => {
  return {
    getEmailGraphAPICall: (data: any) => dispatch(getEmailGraphAPICall.request(data)),
    getEmailDashboardAPICall: (data: any) => dispatch(getEmailDashboardAPICall.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StatsFilters)
